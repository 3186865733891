<nav class="navbar navbar-expand-lg bg-body-tertiary" style="background-color: #62eec7"
  *ngIf="ls.userObj?.user || ls.userObj?.admin">
  <div class="container-fluid ">

    <div>
      <img height="25px" width="70px"
        src="https://lex.infosysapps.com/assets/instances/Lex/app_logos/lex-logo-transparent.svg">

      <a class="navbar-brand ms-0 h3" style="color:#010028;">SynthAIz</a>
    </div>



    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="d-flex justify-content-end ms-3">
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav ">

          <span class="navbar-brand mb-0 h3 topic   " style="color:#010028"><a
            href="https://ai.onwingspan.com/domaindossier/" target="_blank"
            style="color:black;text-decoration:none">Domain Dossier</a></span>

          <span class="navbar-brand mb-0 h3 topic   " style="color:#010028"><a
              href="https://ai.onwingspan.com/vertical-compliance/" target="_blank"
              style="color:black;text-decoration:none">Vertical Compliance</a></span>

          <!-- <span class="navbar-brand mb-0 h3 topic    " style="color:#010028" [routerLink]="['/course']">Course</span> -->
          <span class="navbar-brand mb-0 h3 topic  " style="color:#010028" [routerLink]="['/embed-sow']">Sow Analyser</span>

          <span class="navbar-brand mb-0 h3 topic  " style="color:#010028" [routerLink]="['/embed-glossary']">Glossary</span>
          <span class="navbar-brand mb-0 h3 topic  " style="color:#010028" [routerLink]="['/embed-agon']">Agon</span>
          <!-- <span class="navbar-brand mb-0 h3 topic   " style="color:#010028"><a
              href="https://ai.onwingspan.com/glossary/" target="_blank"
              style="color:black;text-decoration:none">Glossary</a></span> -->
              
          <span class="navbar-brand mb-0 h3 topic  " style="color:#010028" [routerLink]="['/domain']">Domain</span>
          <span class="navbar-brand mb-0 h3 topic  " style="color:#010028"
            [routerLink]="['/organization']">Organization</span>

          <span class="navbar-brand mb-0 h3 topic  " style="color:#010028" [routerLink]="['/theme']">GeniAI</span>
          <span class="navbar-brand mb-0 h3 topic  " style="color:#010028" [routerLink]="['/zara']">Zara</span>

          <span class="navbar-brand mb-0 h3 topic  " [routerLink]="['/user']" *ngIf="ls.userObj?.admin"
            style="color:#010028" role="button">Add User</span>
          <span class="navbar-brand mb-0 h3 topic   " style="color:#010028"><a
              href="https://ai.onwingspan.com/capstone/" target="_blank"
              style="color:black;text-decoration:none">Capstone</a></span>
<span class="navbar-brand mb-0 h3 topic   " style="color:#010028"><a
              href="https://ai.onwingspan.com/classroom/" target="_blank"
              style="color:black;text-decoration:none">Classroom</a></span>




        </div>
      </div>
    </div>

  </div>
</nav>


<!-- toggle chatbot -->


<!-- <div id="do" *ngIf="!showChat" class="ms-auto photo position-fixed" data-toggle="tooltip" data-placement="left"
  title="Need help? Try Synthaiz assistant" (click)="showChat=!showChat;startLoader()"
  style="background-image: url('../assets/ch.jpg');width: 45px;height: 45px;bottom: 10px;right:50px;">

</div> -->

<div id="do" *ngIf="!botStyle" class="ms-auto photo position-fixed" data-toggle="tooltip" data-placement="left"
title="Need help? Try Synthaiz assistant" (click)="changebot()"
style="background-image: url('../assets/ch.jpg');width: 45px;height: 45px;bottom: 10px;right:50px;">

</div>



<!-- chatbot -->
<div class="container p-3 position-absolute" style="bottom: 2px;right:10px;animation: fadeIn 1s;z-index:3;" id="red"
  *ngIf="showChat">
  <div class="row">
    <!-- <button (click)="scroll()">click</button> -->

    <div class="bg-white" style="padding: 0;border-radius: 10px;"
      [ngClass]="{'col-md-3':!expand,'offset-9':!expand, 'col-md-12':expand}">
      <div class="chat">
        <div class="header-chat" style="background-color:#010028;">
          <div class="ms-3 photo" style="background-image: url('../assets/dhicon.png');">
            <div class="online"></div>
          </div>
          <p class="name">Zoiee</p>
          <i class="fa fa-expand  me-1 ms-auto" style="font-size:22px;color:white;" *ngIf="!expand"
            (click)="expand=true;" data-placement="bottom"
            title="Maximize"></i>
          <i class="fa fa-window-minimize ms-auto mb-2 me-1" style="font-size:22px;color:white;" *ngIf="expand"
            (click)="expand=false;" data-placement="bottom"
            title="Minimize"></i>
            <i class="fa fa-refresh  me-1 me-2 ms-2" style="font-size:22px;color:white;" (click)="refresh()"
            data-placement="bottom"
            title="Refresh Chat"></i>
          <b class="ms-2 me-2 cancel text-white" (click)="showChat=false;checkLoader()" >&#x2715;</b>
        </div>
        <div class="messages-chat" id="data">
          <div *ngFor="let msg of chatArray;let i=index;">
            <div class="message" *ngFor="let txt of msg;let j=index;">
              <!-- <div class="profile" *ngIf="i%2==0 && j==0" style="background-image: url('../assets/ch.jpg');">
                <div class="online"></div>
              </div> -->
              <div class="typing-loader position-absolute" style="left:40px;top:6px;"
                *ngIf="i%2==0 && j==0 && !ldArr[i]"></div>
              <p class="callout arrow-left" *ngIf="i%2==0 && txt && ldArr[i]" [ngClass]="{'mt':i>0}"> {{txt}} </p>
              <div class="response" *ngIf="i%2!=0 && txt && ldArr[i]">
                <p class="text">{{txt}}</p>
              </div>




            </div>
            <p class="time" *ngIf="ldArr[i]" [ngClass]="{'response-time':i%2!=0}"> {{timer[i]}}</p>


          </div>

          <!-- <div class="message text-only">
            <div class="response">
              <p class="text"> Hey, can you explain a brief on component of angular?</p>
            </div>
          </div>
          <div class="message text-only">
            <div class="response">
              <p class="text">Also share example on the same?</p>
            </div>
          </div>
          <p class="response-time time"> 15h04</p>
          <div class="message">
            <div class="profile"
              style="background-image: url('../assets/ch.jpg');">
              <div class="online"></div>
            </div>
            <p class="text"> Components are the main building block for Angular applications. 
              Components are the main building block for Angular applications... </p>
              
          </div>
          <div class="message text-only">
            <p class="text">     Each component consists of: An HTML template that declares what renders on the page ...</p>
          </div> -->

        </div>

        <div style="display: inline-block;" *ngIf="flag">
          <button class="button_1" (click)="learningType('adaptive')">Adaptive Learning</button>
          <button class="button_1" (click)="learningType('socrative')">Socrative Learning</button>
          <button class="button_1" (click)="learningType('analogy')">Analogy Learning</button>
          <button class="button_1" (click)="learningType('active')">Active Recall</button>
          <button class="button_1" (click)="learningType('storytelling')">Story Telling</button>
        </div>







        <div class="footer-chat">
          <svg (click)=" recorder('chat')" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            style="color:black;position:absolute;right:40px;" class="bi bi-mic acc " viewBox="0 0 16 16" id="go" >
            <path
              d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
            <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
          </svg>
          &nbsp;

          <i class="fa fa-paper-plane" style="position:absolute;right:10px;" (click)="getChatResponse(userInput)"
            aria-hidden="true"></i>


          <div class="pulse-ring" *ngIf="pulse"></div>
          <input type="text" id="inp" class="write-message" placeholder="Type your message here" [(ngModel)]="userInput"
            (keypress)="checkKey($event)" />

        </div>
      </div>
    </div>


  </div>
</div>

<!--  -->


<router-outlet></router-outlet>


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddUserComponent } from './add-user/add-user.component';
import { CourseComponent } from './course/course.component';
import { DomainComponent } from './domain/domain.component';
import { ErrorComponent } from './error/error.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { OrganisationComponent } from './organisation/organisation.component';
import { ThemeComponent } from './theme/theme.component';
import { EmbedComponent } from './embed/embed.component';
import { EmbedBotComponent } from './embed-bot/embed-bot.component';
import { ZaraComponent } from './zara/zara.component';
import { SowComponent } from './sow/sow.component';


const routes: Routes = [
  // { path: "course", component: CourseComponent, canActivate: [LoginService] },
  { path: "glossary", component: GlossaryComponent, canActivate: [LoginService] },
  { path: "domain", component: DomainComponent, canActivate: [LoginService] },
  { path: "user", component: AddUserComponent, canActivate: [LoginService] },
  { path: "login", component: LoginComponent },
  { path: "theme", component: ThemeComponent },
  { path: "organization", component: OrganisationComponent, canActivate: [LoginService] },
  { path: "error", component: ErrorComponent },
  { path: "embed-glossary", component: EmbedComponent, canActivate: [LoginService] },
  { path: "embed-bot", component: EmbedBotComponent },
  { path: "zara", component: ZaraComponent },
  { path: "embed-sow", component: SowComponent },

  { path: "**", redirectTo: "/embed-glossary", pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TypeofPipe } from './typeof.pipe';
import { LvPipe } from './lv.pipe';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';
import { CourseComponent } from './course/course.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { ValidatePipe } from './validate.pipe';
import { DomainComponent } from './domain/domain.component';
import { LoginComponent } from './login/login.component';
import { OrganisationComponent } from './organisation/organisation.component';
import { CustomInterceptor } from './token.interceptor';
import { AddUserComponent } from './add-user/add-user.component';
import { ErrorComponent } from './error/error.component';
import { ThemeComponent } from './theme/theme.component';
import { EmbedComponent } from './embed/embed.component';
import { EmbedBotComponent } from './embed-bot/embed-bot.component';
import { ZaraComponent } from './zara/zara.component';
import { LoginService } from './login/login.service';
import { SowComponent } from './sow/sow.component';



const monacoConfig: NgxMonacoEditorConfig = {
  baseUrl: './assets', // configure base path for monaco editor. Starting with version 8.0.0 it defaults to './assets'. Previous releases default to '/assets'
  defaultOptions: { scrollBeyondLastLine: false }, // pass default options to be used
  onMonacoLoad: () => {
    console.log((<any>window).monaco);
    (<any>window).monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: true,
    });

  } // here monaco object will be available as window.monaco use this function to extend monaco editor functionalities.
};

@NgModule({
  declarations: [
    AppComponent,
    TypeofPipe,
    LvPipe,
    CourseComponent,
    GlossaryComponent,
    ValidatePipe,
    DomainComponent,
    LoginComponent,
    OrganisationComponent,
    AddUserComponent,
    ErrorComponent,
    ThemeComponent,
    EmbedComponent,
    EmbedBotComponent,
    ZaraComponent,
    SowComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MonacoEditorModule.forRoot(monacoConfig)
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true }
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
